<template>
  <div>
    <b-modal
      :id="$route.name + 'EditModal'"
      title="Редактирование цветь овтомобила"
      size="s"
      hide-footer
      no-enforce-focus
      :no-close-on-backdrop="true"
    >
      <b-row>
        <b-col cols="12" class="mt-1">
          <label for="">Название</label>
          <b-input v-model="colorType.name" placeholder="Название" />
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" class="mt-1">
          <label for="">Название тч</label>
          <b-input v-model="colorType.name_tj" placeholder="Название тч" />
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" class="mt-1">
          <label for="">Название для смс</label>
          <b-input
            v-model="colorType.name_for_sms"
            placeholder="Название для смс"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col class="mt-1">
          <b-form-checkbox
            v-model="colorType.is_active"
            class="custom-control-primary"
          >
            {{ colorType.is_active ? "Активный" : "Неактивный" }}
          </b-form-checkbox>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" class="d-flex justify-content-between">
          <b-button
            :disabled="submitButtonDisabled"
            class="mt-2 col-md-5"
            variant="primary"
            @click="editBobyType"
          >
            Изменить
          </b-button>
          <b-button
            class="mt-2 col-md-5"
            variant="secondary"
            @click="closeModal"
          >
            Отмена
          </b-button>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import { GlobalEventEmitter } from "@/utils/GlobalEventEmitter.js";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
export default {
  component: {
    ToastificationContent,
  },
  props: ["color"],
  data() {
    return {
      colorType: {
        name: null,
        name_tj: null,
        name_for_sms: null,
        is_active: null,
      },
      colorSelectDisabled: false,
      submitButtonDisabled: false,
    };
  },
  methods: {
    clearData() {
      this.colorType = {
        name: null,
        name_tj: null,
        name_for_sms: null,
        is_active: null,
      };
    },
    closeModal() {
      this.$bvModal.hide(this.$route.name + "EditModal");
    },
    editBobyType() {
      this.submitButtonDisabled = true;
      this.$http
        .patch(`/car-settings/car-colors/${this.colorType.id}`, {
          name: this.colorType.name,
          name_tj: this.colorType.name_tj,
          name_for_sms: this.colorType.name_for_sms,
          is_active: this.colorType.is_active,
        })
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: "Цветь машини изменен!",
              icon: "CheckIcon",
              variant: "success",
            },
          });
          this.$emit("refresh");
          this.clearData();
          this.closeModal();
        })
        .catch((err) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: "Некорректные цвет",
              icon: "XIcon",
              variant: "danger",
              text: err.response.data.errors,
            },
          });
        })
        .finally(() => {
          this.submitButtonDisabled = false;
        });
    },
  },
  created() {
    // adds the event listener function that will handle the event
    GlobalEventEmitter.$on("openEditCarBodyTypeModal", (colorType) => {
      this.$http
        .get(`/car-settings/car-colors/${colorType.id}/edit`)
        .then((res) => {
          this.colorType = res.data;
          this.colorType.is_active = this.colorType.is_active ? true : false;
          this.$bvModal.show(this.$route.name + "EditModal");
        });
    });
  },
  destroy() {
    // removes event listener
    GlobalEventEmitter.$off("openEditCarBodyTypeModal");
  },
};
</script>

<style></style>
